import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import PopComponent from "../../../../hoc/PopContent";
import {
  addAddInAppList,
  addBotList,
  updateAddInAppList,
  updateBotList,
} from "../../../../Redux/Master/action";
import { jsonToFormData, profileImages } from "../../../../utils";
import { Box } from "@mui/material";
import user from "../../../../assets/images/avatar.png";
import icon_plus from "../../../../assets/images/plus.svg";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import FilledButton from "../../../../Components/FileButton";
import CommonModal from "../../../../hoc/CommonModal";
import CommonDropdown from "../../../../Components/Dropdown/CommonDropdown";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "5px",
};

const AddInApp = ({ modalValue, handleOpenModal, redirectApiHandler }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);
  const [modalDetails, setModalDetails] = useState({
    modalValue: "",
    modalName: "",
    modalIsOpen: false,
  });
  let Modal = PopComponent[modalDetails.modalName];
  const [formData, setFormData] = useState({
    name: "",
    title: "",
    price: "",
    coins: "",
    isImageUpdated: false,
    inAppStoreImage: "",
    platform: "",
  });

  const handleEditSubmit = (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      let payload = {
        ...formData,
        inAppId: modalValue?.row?._id,
      };
      if (!payload?.isImageUpdated) {
        delete payload?.inAppStoreImage;
      }
      setLoader(true);
      dispatch(updateAddInAppList(jsonToFormData(payload))).then((res) => {
        if (res.data.success) {
          setLoader(false);
          redirectApiHandler();
          handleOpenModal("CommonPop", {
            header: "Success",
            body: res?.data?.message,
          });
        } else {
          setLoader(false);
          handleOpenModal("CommonPop", {
            header: "Error",
            body: res?.data?.message || res?.data?.msg,
          });
        }
      });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      let payload = {
        ...formData,
      };
      setLoader(true);
      dispatch(addAddInAppList(jsonToFormData(payload))).then((res) => {
        if (res.data.success) {
          setLoader(false);
          redirectApiHandler();
          handleOpenModal("CommonPop", {
            header: "Success",
            body: res?.data?.message,
          });
        } else {
          setLoader(false);
          handleOpenErrorModal("CommonPop", {
            header: "Error",
            body: res?.data?.message || res?.data?.msg,
          });
        }
      });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate();
    }
  };

  const changeHandler = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleOpenErrorModal = (type, data) => {
    switch (type) {
      case "CommonPop": {
        setModalDetails({
          ...modalDetails,
          modalValue: data,
          modalName: type,
          modalIsOpen: true,
        });
        break;
      }
      default: {
        setModalDetails({ ...modalDetails, modalIsOpen: false });
      }
    }
  };

  useEffect(() => {
    if (modalValue?.isEdit) {
      setFormData({
        ...formData,
        inAppStoreImage: modalValue?.row?.inAppStoreImage,
        name: modalValue?.row?.name,
        platform: modalValue?.row?.platform,
        coins: modalValue?.row?.coins,
        price: modalValue?.row?.price,
        title: modalValue?.row?.title,
      });
    }
  }, [modalValue?.isEdit]);

  return (
    <Box sx={style}>
      <div
        className={"modal_main_popup bot_section_details lobby_section_details"}
      >
        <div className={"modal_popup_title"}>
          <h2>{modalValue?.isEdit ? "Update In App" : "Add In App"}</h2>
        </div>
        <div className={"add_admin_user_popup_content mt_15"}>
          <form
            onSubmit={
              modalValue?.isEdit
                ? (e) => handleEditSubmit(e)
                : (e) => handleSubmit(e)
            }
          >
            <div className={"level_popup_form_field "}>
              <div className="form_group profile new_game_section profile-image-dropdown">
                <div className="user_profile">
                  <div className="user_profile_pic">
                    {profileImages(formData?.inAppStoreImage, user)}
                    {!formData?.inAppStoreImage && (
                      <span className="addnew">
                        <img src={icon_plus} alt="" />
                        <input
                          type="file"
                          name="profileImage"
                          id=""
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (
                              file?.type?.includes("image/") &&
                              file.type !== "image/gif" &&
                              file.type !== "image/svg+xml"
                            ) {
                              if (file.size <= 5 * 1024 * 1024) {
                                // Check if file size is less than or equal to 5 MB
                                setFormData({
                                  ...formData,
                                  inAppStoreImage: file,
                                  isImageUpdated: true,
                                });
                              } else {
                                handleOpenErrorModal("CommonPop", {
                                  header: "Error",
                                  body: "Please select an image file smaller than 5 MB.",
                                });
                              }
                            } else {
                              handleOpenErrorModal("CommonPop", {
                                header: "Error",
                                body: "Please add only .jpg, .jpeg, .png files.",
                              });
                            }
                          }}
                        />
                      </span>
                    )}
                  </div>
                  <label htmlFor="" className="profile_label">
                    {" "}
                    Profile <spa className={"validation-star"}>*</spa>
                  </label>
                </div>
                {simpleValidator.current.message(
                  "Profile Image",
                  formData?.inAppStoreImage,
                  "required"
                )}
                {formData?.inAppStoreImage && (
                  <div
                    className={"close-icon"}
                    onClick={() =>
                      setFormData({ ...formData, inAppStoreImage: "" })
                    }
                  >
                    <CloseSharpIcon />
                  </div>
                )}
              </div>
              <div className={"level_popup_form_field_left"}>
                <div className={"user_kyc_section"}>
                  <div className={"user_kyc_section_filed"}>
                    <label>
                      Name <span className={"validation-star"}>*</span>
                    </label>
                    <div
                      className={
                        "user_kyc_section_input_filed lobby-type-description"
                      }
                    >
                      <input
                        type={"text"}
                        placeholder={"Enter Name"}
                        maxLength={20}
                        value={formData?.name}
                        name={"name"}
                        onChange={(e) => changeHandler(e)}
                      />
                      <span>{formData?.name?.length || 0}/20</span>
                    </div>
                    {simpleValidator.current.message(
                      "Name",
                      formData?.name,
                      "required"
                    )}
                  </div>
                </div>

                <div className={"user_kyc_section"}>
                  <div className={"user_kyc_section_filed"}>
                    <label>
                      Title <span className={"validation-star"}>*</span>
                    </label>
                    <div
                      className={
                        "user_kyc_section_input_filed lobby-type-description"
                      }
                    >
                      <input
                        type={"text"}
                        placeholder={"Enter Title"}
                        maxLength={50}
                        value={formData?.title}
                        name={"title"}
                        onChange={(e) => changeHandler(e)}
                      />
                      <span>{formData?.title?.length || 0}/50</span>
                    </div>
                    {simpleValidator.current.message(
                      "Title",
                      formData?.title?.toString(),
                      "required"
                    )}
                  </div>
                </div>

                <div className={"user_kyc_section_filed readOnly_field"}>
                  <label>
                    Type <span className={"validation-star"}>*</span>
                  </label>
                  <div className={"user_kyc_section_input_filed mt_margin"}>
                    <CommonDropdown
                      options={["Android", "iOS"]}
                      name={"platform"}
                      setFormData={setFormData}
                      formData={formData}
                      placeholder={'Select Type'}
                    />
                  </div>
                  {simpleValidator.current.message(
                    "Type",
                    formData?.platform?.toString(),
                    "required"
                  )}
                </div>
                <div className={"user_kyc_section mt_margin"}>
                  <div className={"user_kyc_section_filed"}>
                    <label>
                      Price <span className={"validation-star"}>*</span>
                    </label>
                    <div className={"user_kyc_section_input_filed"}>
                      <input
                        type={"text"}
                        placeholder={"Enter Price"}
                        value={formData?.price}
                        name={"price"}
                        onChange={(e) => {
                          let numberRegex = /^\d+$/;
                          setFormData({
                            ...formData,
                            price: numberRegex.test(e.target.value)
                              ? e.target.value
                              : "",
                          });
                        }}
                      />
                    </div>
                    {simpleValidator.current.message(
                      "Price",
                      formData?.price?.toString(),
                      "required|numeric|min:0|max:10"
                    )}
                  </div>
                </div>
                <div className={"user_kyc_section"}>
                  <div className={"user_kyc_section_filed"}>
                    <label>
                      Coin <span className={"validation-star"}>*</span>
                    </label>
                    <div className={"user_kyc_section_input_filed lobby-type-description"}>
                      <input
                        type={"text"}
                        placeholder={"Enter Coin"}
                        value={formData?.coins}
                        name={"coins"}
                        maxLength={10}
                        onChange={(e) => {
                          let numberRegex = /^\d+$/;
                          setFormData({
                            ...formData,
                            coins: numberRegex.test(e.target.value)
                              ? e.target.value
                              : "",
                          });
                        }}
                      />
                       <span>{formData?.coins?.length || 0}/10</span>
                    </div>
                    {simpleValidator.current.message(
                      "Coin",
                      formData?.coins?.toString(),
                      "required"
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={"formData_btn d_flex_end mt_2"}>
              <button
                className={"btn_default"}
                type={"reset"}
                onClick={() => handleOpenModal()}
              >
                Cancel
              </button>
              <FilledButton
                type={"submit"}
                value={modalValue?.isEdit ? "Update" : "Save"}
                className={"btn loader_css"}
                loading={loader}
              />
            </div>
          </form>
        </div>
      </div>
      <CommonModal
        className={"Approved-reject-section"}
        modalIsOpen={modalDetails.modalIsOpen}
        handleOpenModal={handleOpenErrorModal}
      >
        <Modal
          modalValue={modalDetails.modalValue}
          handleOpenModal={handleOpenErrorModal}
          modalIsOpen={modalDetails.modalIsOpen}
        />
      </CommonModal>
    </Box>
  );
};
export default AddInApp;
