import React, {useCallback, useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import {addHelpAndSupportEmail} from "../../../../../../Redux/HelpAndSupport/action";
import {Box} from "@mui/material";
import FilledButton from "../../../../../../Components/FileButton";
import DropdownList from "../../../../../../Components/Dropdown/DropdownList";
import {addMonthlyReferAndEarnBonusAmountList} from "../../../../../../Redux/Bonus/action";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 486,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
};
const AddMonthlyBonusAmount = ({ modalValue, handleOpenModal, redirectApiHandler }) => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const simpleValidator = useRef(new SimpleReactValidator());
    const [, updateState] = useState({});
    const forceUpdate = useCallback(() => updateState({}), []);
    const [formData, setFormData] = useState({ maxMonthlyBonusLimit: '', depositInto:'', isDeductTds:false });
    const handleSubmit = (e) => {
        e.preventDefault();
        if (simpleValidator.current.allValid()) {
            setLoader(true)
            let payload = {
                maxMonthlyBonusLimit:formData?.maxMonthlyBonusLimit
            }
            dispatch(addMonthlyReferAndEarnBonusAmountList(payload)).then(res => {
                if (res.data.success) {
                    setLoader(false)
                    redirectApiHandler();
                    handleOpenModal('CommonPop', { header: "Success", body: res?.data?.message })
                } else {
                    setLoader(false)
                    handleOpenModal('CommonPop', { header: "Error", body: res?.data?.message || res?.data?.msg })
                }
            })
        } else {
            simpleValidator.current.showMessages();
            forceUpdate();
        }
    }

    useEffect(() => {
        if (modalValue?.isEdit) {
            setFormData({ ...formData,
                maxMonthlyBonusLimit: modalValue?.row?.maxMonthlyBonusLimit,
                depositInto: modalValue?.row?.depositInto === 'WinCash' ?  'Winning Cash' : modalValue?.row?.depositInto === 'DepositCash' ? 'Deposit Cash' : modalValue?.row?.depositInto === 'Bonus' && 'Bonus Cash',
                isDeductTds: modalValue?.row?.isDeductTds || false
            })
        }
    }, [modalValue?.isEdit]);

    return (
        <Box sx={style}>
            <div className={'add_admin_user_popup modal_main_popup'}>
                <div className={'modal_popup_title'}>
                    <h2>{modalValue?.isEdit ? "Update Monthly Bonus Amount" : ' Add Monthly Bonus Amount'}</h2>
                </div>
                <div className={'add_admin_user_popup_content'}>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className={'user_kyc_section'}>
                            <div className={'user_kyc_section_filed'}>
                                <label>Bonus Amount <span className={'validation-star'}>*</span></label>
                                <div className={'user_kyc_section_input_filed'}>
                                <input
  type="text"
  placeholder="Enter Bonus Amount"
   onWheel={(e) => e.currentTarget.blur()}
                                        value={formData?.maxMonthlyBonusLimit}
                                        maxLength={10}
  onChange={(e) => {
    const { value, name } = e.target;
    const sanitizedValue = value.replace(/[^0-9]/g, ""); // Replace non-numeric characters

    setFormData({ ...formData, maxMonthlyBonusLimit: sanitizedValue });
  }}
/>

                                </div>
                                {simpleValidator.current.message("bonusAmount", formData?.maxMonthlyBonusLimit?.toString(), 'required|numeric|min:0|max:10')}
                            </div>
                        </div>
                        <div className={'formData_btn'}>
                            <button className={'btn_default'} type={'reset'} onClick={() => handleOpenModal()}>Cancel</button>
                            <FilledButton type={'submit'} value={modalValue?.isEdit ? 'Update' : 'Save'} className={'btn loader_css'} loading={loader} />
                        </div>
                    </form>
                </div>
            </div>
        </Box>
    )
}
export default AddMonthlyBonusAmount