import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import PopComponent from "../../../../hoc/PopContent";
import { Box } from "@mui/material";
import FilledButton from "../../../../Components/FileButton";
import CommonModal from "../../../../hoc/CommonModal";
import CommonDropdown from "../../../../Components/Dropdown/CommonDropdown";
import { addErrorCodeList, getAllErrorCode, updateErrorCodeList } from "Redux/settings/action";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "5px",
};

const AddErrorCodePopUp = ({ modalValue, handleOpenModal, redirectApiHandler }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);
  const [modalDetails, setModalDetails] = useState({
    modalValue: "",
    modalName: "",
    modalIsOpen: false,
  });
  let Modal = PopComponent[modalDetails.modalName];
  const [formData, setFormData] = useState({
    message : "",
    code: "",
    messageType: ""
  });

  const handleEditSubmit = (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      let payload = {
        ...formData,
        errorCodeId : modalValue?.row?._id,
      };
      if (!payload?.isImageUpdated) {
        delete payload?.inAppStoreImage;
      }
      setLoader(true);
      dispatch(updateErrorCodeList(payload)).then((res) => {
        if (res.data.success) {
          setLoader(false);
          redirectApiHandler();
          handleOpenModal("CommonPop", {
            header: "Success",
            body: res?.data?.message,
          });
        } else {
          setLoader(false);
          handleOpenModal("CommonPop", {
            header: "Error",
            body: res?.data?.message || res?.data?.msg,
          });
        }
      });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      let payload = {
        ...formData,
      };
      setLoader(true);
      dispatch(addErrorCodeList(payload)).then((res) => {
        if (res.data.success) {
          setLoader(false);
          redirectApiHandler();
          handleOpenModal("CommonPop", {
            header: "Success",
            body: res?.data?.message,
          });
        } else {
          setLoader(false);
          handleOpenErrorModal("CommonPop", {
            header: "Error",
            body: res?.data?.message || res?.data?.msg,
          });
        }
      });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate();
    }
  };

  const changeHandler = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleOpenErrorModal = (type, data) => {
    switch (type) {
      case "CommonPop": {
        setModalDetails({
          ...modalDetails,
          modalValue: data,
          modalName: type,
          modalIsOpen: true,
        });
        break;
      }
      default: {
        setModalDetails({ ...modalDetails, modalIsOpen: false });
      }
    }
  };

  useEffect(() => {
    if (modalValue?.isEdit) {
      setFormData({
        ...formData,
        message: modalValue?.row?.message,
        messageType: modalValue?.row?.messageType,
        code: modalValue?.row?.code,
      });
    }
  }, [modalValue?.isEdit]);

  return (
    <Box sx={style}>
      <div
        className={"modal_main_popup bot_section_details lobby_section_details"}
      >
        <div className={"modal_popup_title"}>
          <h2>{modalValue?.isEdit ? "Update Error Code" : "Add Error Code"}</h2>
        </div>
        <div className={"add_admin_user_popup_content mt_15"}>
          <form
            onSubmit={
              modalValue?.isEdit
                ? (e) => handleEditSubmit(e)
                : (e) => handleSubmit(e)
            }
          >
            <div className={"level_popup_form_field "}>
              <div className={"level_popup_form_field_left"}>
                <div className={"user_kyc_section"}>
                  <div className={"user_kyc_section_filed"}>
                    <label>
                      Message <span className={"validation-star"}>*</span>
                    </label>
                    <div
                      className={
                        "user_kyc_section_input_filed lobby-type-description"
                      }
                    >
                      <input
                        type={"text"}
                        placeholder={"Enter Message"}
                        maxLength={200}
                        value={formData?.message}
                        name={"message"}
                        onChange={(e) => changeHandler(e)}
                      />
                      <span>{formData?.message?.length || 0}/200</span>
                    </div>
                    {simpleValidator.current.message(
                      "message",
                      formData?.message?.toString(),
                      "required"
                    )}
                  </div>
                </div>

                <div className={"user_kyc_section_filed readOnly_field"}>
                  <label>
                    Message Type <span className={"validation-star"}>*</span>
                  </label>
                  <div className={"user_kyc_section_input_filed mt_margin"}>
                    <CommonDropdown
                      options={["success", "error"]}
                      name={"messageType"}
                      setFormData={setFormData}
                      formData={formData}
                    />
                  </div>
                  {simpleValidator.current.message(
                    "messageType",
                    formData?.messageType?.toString(),
                    "required"
                  )}
                </div>
                <div className={"user_kyc_section mt_margin"}>
                  <div className={"user_kyc_section_filed"}>
                    <label>
                      Code <span className={"validation-star"}>*</span>
                    </label>
                    {modalValue?.isEdit ? 
                    <div className={modalValue?.isEdit ?"user_kyc_section_input_filed lobby-type-description readOnly_field" : "user_kyc_section_input_filed lobby-type-description"}>
                      <input
                        type={"text"}
                        placeholder={"Enter Code"}
                        value={formData?.code}
                        name={"code"}
                        maxLength={10}
                      />
                       <span>{formData?.code?.length || 0}/10</span>
                    </div>
                    : 
                    <div className={modalValue?.isEdit ?"user_kyc_section_input_filed lobby-type-description readOnly_field" : "user_kyc_section_input_filed lobby-type-description"}>
                      <input
                        type={"text"}
                        placeholder={"Enter Code"}
                        value={formData?.code}
                        name={"code"}
                        maxLength={10}
                        onChange={(e) => {
                          let numberRegex = /^\d+$/;
                          setFormData({
                            ...formData,
                            code: numberRegex.test(e.target.value)
                              ? e.target.value
                              : "",
                          });
                        }}
                      />
                       <span>{formData?.code?.length || 0}/10</span>
                    </div>}
                    {simpleValidator.current.message(
                      "code",
                      formData?.code?.toString(),
                      "required"
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={"formData_btn d_flex_end mt_2"}>
              <button
                className={"btn_default"}
                type={"reset"}
                onClick={() => handleOpenModal()}
              >
                Cancel
              </button>
              <FilledButton
                type={"submit"}
                value={modalValue?.isEdit ? "Update" : "Save"}
                className={"btn loader_css"}
                loading={loader}
              />
            </div>
          </form>
        </div>
      </div>
      <CommonModal
        className={"Approved-reject-section"}
        modalIsOpen={modalDetails.modalIsOpen}
        handleOpenModal={handleOpenErrorModal}
      >
        <Modal
          modalValue={modalDetails.modalValue}
          handleOpenModal={handleOpenErrorModal}
          modalIsOpen={modalDetails.modalIsOpen}
        />
      </CommonModal>
    </Box>
  );
};
export default AddErrorCodePopUp;
