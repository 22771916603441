import React from "react";
const DashBoardData = React.lazy(() => import("Pages/DashBoardData"));
//auth
const Login = React.lazy(() => import("Pages/Authentication/Login"));
const ResetPassword = React.lazy(() => import("../Pages/Authentication/ResetPassword"));
const ChangePassword = React.lazy(() => import("Pages/Authentication/ChangePassword"));

//Admin Users
const SubAdminUser = React.lazy(() => import("Pages/SubAdminUser"));
const AdminUser = React.lazy(() => import("Pages/AdminUser"));
const UserData = React.lazy(() => import("../Pages/Users/AllUserList"))
const InactiveUsersList = React.lazy(() => import("../Pages/Users/InactiveUsersList"))
const UsersTab = React.lazy(() => import("../Pages/Users/UserTab"));
const BlockUserList = React.lazy(() => import("../Pages/Users/BlockUserList"));
const ReportedUser = React.lazy(() => import("Pages/Users/ReportedUser"));
const UpdateUserMobileNumber = React.lazy(() => import("../Pages/Users/UpdateUserMobileNumber"));
const LudoLobby = React.lazy(() => import("../Pages/LudoLobby"));

//Games
const AddNewGame = React.lazy(() => import("../Pages/Games/AddNewGame"))
const AllGameList = React.lazy(() => import("../Pages/Games/AllGameList"))
const ApprovedGameList = React.lazy(() => import("../Pages/Games/ApprovedGameList"))
const PendingGameList = React.lazy(() => import("../Pages/Games/PendingGameList"))
const RejectedGameList = React.lazy(() => import("../Pages/Games/RejectedGameList"))
const ArchiveGames = React.lazy(() => import("../Pages/Games/ArchiveGames"));
const GameDetails = React.lazy(() => import("../Pages/Games/GameDetails"));
const GameHistory = React.lazy(() => import("../Pages/Games/GameHistory"));
//GameHistory

//Master
const SplashScreen = React.lazy(() => import("../Pages/Master/SplashScreen"));
const BotModule = React.lazy(() => import("../Pages/Master/BotModule"));
const Avatar = React.lazy(() => import("../Pages/Master/Avatar"));
const Document = React.lazy(() => import("../Pages/Master/Document"));
const LobbyLabel = React.lazy(() => import("../Pages/Master/LobbyLabel"));
const GenreCategory = React.lazy(() => import("../Pages/Master/GenreCategory"));
const MGPOnlinePlayer = React.lazy(() => import("../Pages/Master/MGPOnlinePlayer"));
const InternalAdsList = React.lazy(() => import("../Pages/Marketing/InternalAdsList"));
const GameModeDesignConfig = React.lazy(() => import("../Pages/Master/GameModeDesignConfig"));
const ReportReason = React.lazy(() => import("../Pages/Master/ReportReason"));

const Config = React.lazy(() => import("../Pages/Settings/Config"));
const FlagConfig = React.lazy(() => import("../Pages/Settings/FlagConfig"));
const RestrictGeo = React.lazy(() => import("../Pages/Settings/RestrictGeo"));
const ErrorCode = React.lazy(() => import("../Pages/Settings/ErrorCode"));
const NotificationModule = React.lazy(() => import("../Pages/Marketing/NotificationModule"));

//Revenue
const GameWiseRevenue = React.lazy(() => import("../Pages/Revenue/GameWiseRevenue"));
const PackageStoreRevenue = React.lazy(() => import("../Pages/Revenue/PackageStoreRevenue"));

//Bonus
const DailyWheelBonus = React.lazy(() => import("../Pages/Bonus/DailyWheelBonus"));
const ReferAndEarn = React.lazy(() => import("../Pages/Bonus/ReferAndEarn"));


const HelpAndSupport = React.lazy(() => import("../Pages/HelpAndSupport"));

const PopularGames = React.lazy(() => import("../Pages/PopularGames"));
const MGPRelease = React.lazy(() => import("../Pages/MGPRelease"));


const Maintenance = React.lazy(() => import("../Pages/Settings/Maintenance"));

const OfferTab = React.lazy(() => import("../Pages/Marketing/OfferTab"));

const AnalyticsReport = React.lazy(() => import("../Pages/Analytics/AnalyticsReport"));
const AnalyticsOverviewReport = React.lazy(() => import("../Pages/Analytics/AnalyticsOverviewReport"));
const AnalyticsApplicationInstallReport = React.lazy(() => import("../Pages/Analytics/AnalyticsApplicationInstallReport"));

const CompanyLogo = React.lazy(() => import("../Pages/CompanyLogo"));

const PlatformReport = React.lazy(() => import("../Pages/Revenue/PlatformReport"));

const DailyReport = React.lazy(() => import("../Pages/Analytics/DailyReport"));

const LoginScreen = React.lazy(() => import("../Pages/Design/LoginScreen"));
const HomeScreen = React.lazy(() => import("../Pages/Design/HomeScreen"));
const ReferAndEarnDesign = React.lazy(() => import("../Pages/Design/ReferAndEarn"));
const InApp = React.lazy(() => import("../Pages/Master/InApp"));
const GameConfig = React.lazy(() => import("../Pages/GameConfig"));

export const PublicroutesArray = [
    { path: "/", exact: true, component: Login },
    { path: "/reset/:id/:id", component: ResetPassword, title: "Reset Password" },
];

export const PrivateroutesArray = [
    { path: "/dashboard-data", component: DashBoardData, title: "Dashboard" },
    { path: "/change-password", component: ChangePassword, title: "Change Password" },
    { path: "/admin-users", component: AdminUser, title: "Admin Users" },
    //User
    { path: "Users", component: UserData, title: "Users" },
    { path: "/inactive-users", component: InactiveUsersList, title: "Inactive Users" },
    //InactiveUsersList
    { path: "users-tab/:id", component: UsersTab, title: "Users Details" },
    { path: "/user-reported", component: ReportedUser, title: "Reported Users" },
    { path: "/block-user", component: BlockUserList, title: "Blocked Users" },
    { path: "/user/update-mobile-number", component: UpdateUserMobileNumber, title: "Users Mobile Number Request" },
    { path: "/ludo-lobby", component: LudoLobby, title: "Lobby" },
    //Game
    { path: "/games/all-games", component: AllGameList, title: "Games" },
    { path: "/games/approved-games", component: ApprovedGameList, title: "Approved Games" },
    { path: "/games/pending-games", component: PendingGameList, title: "Pending Games" },
    { path: "/games/rejected-games", component: RejectedGameList, title: "Rejected Games" },
    { path: "/games/archive-games", component: ArchiveGames, title: "Archive Games" },
    { path: "/games/add-game", component: AddNewGame, title: "Add New Game" },
    { path: "/game-tab/:id", component: GameDetails, title: "Game Details" },
    {path: '/game/game-history', component: GameHistory, title: "Game History"},
    //Master
    { path: "/avatars", component: Avatar, title: "Avatar" },
    { path: "/games/category", component: GenreCategory, title: "Game Category" },
    { path: "/online-players", component: MGPOnlinePlayer, title: "Online Players" },
    { path: "/documentation", component: Document, title: "Documentation" },
    { path: "/ads-list", component: InternalAdsList, title: "Promotion Ads List" },
    { path: "/lobby-label", component: LobbyLabel, title: "Lobby Type" },
    { path: "/splash-screen", component: SplashScreen, title: "Splash Screen" },
    { path: "/bot", component: BotModule, title: "Bot" },
    { path: "/gameModeDesign", component: GameModeDesignConfig, title: "Game Mode Design" },
    { path: "/OfferTab", component: OfferTab, title: "Offer" },

    //withdrawal
    { path: "/setting/config", component: Config, title: "Config" },
    { path: "/setting/restrict-geo", component: RestrictGeo, title: "Restrict Geo" },
    { path: "/setting/error-code", component: ErrorCode, title: "Error Code" },
    { path: "/setting/flag-config", component: FlagConfig, title: "Flag Config" },
    { path: "/notification", component: NotificationModule, title: "Notification" },
    //setting
    { path: "/setting/maintenance", component: Maintenance, title: "Maintenance" },
    { path: "/revenue/game-revenue", component: GameWiseRevenue, title: "Game Revenue" },
    { path: "/revenue/package-store-revenue", component: PackageStoreRevenue, title: "Package Store Revenue" },

    { path: "/daily-spin-bonus", component: DailyWheelBonus, title: "Daily Spin Bonus" },
    { path: "/refer-and-earn", component: ReferAndEarn, title: "Refer & Earn" },
    { path: "/popular-games", component: PopularGames, title: "Popular Games" },
    { path: "/release", component: MGPRelease, title: "Release" },
    { path: "/help-and-support", component: HelpAndSupport, title: "Help & Support" },
    //DailyReport
    { path: "/daily-report", component: DailyReport, title: "Daily Report" },
    { path: "/analytics-report", component: AnalyticsReport, title: "Analytics Report" },
    { path: "/analytics-overview-report", component: AnalyticsOverviewReport, title: "Analytics Overview Report" },
    { path: "/analytics-application-install", component: AnalyticsApplicationInstallReport, title: "Application Install & Uninstall" },
    {path: '/sub-adminUser', component: SubAdminUser, title: "Sub Admin User"},
    {path: '/company-logo', component: CompanyLogo, title: "Company Logo"},
    {path: '/platform-report', component: PlatformReport, title: "Platform Report"},
    {path: '/report-reason', component: ReportReason, title: "Report Reason"},

    {path: "/LoginScreen", component: LoginScreen, title: "Login Screen" },
    {path: "/HomeScreen", component: HomeScreen, title: "Home Screen" },
    {path: "/referAndEarn", component: ReferAndEarnDesign, title: "Refer And Earn" },
    {path: "/in-app", component: InApp, title: "In App"},
    {path: "/GameConfig", component: GameConfig, title: "Game Config"},
];

//GameCategory