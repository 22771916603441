import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import PopComponent from "../../../../../hoc/PopContent";
import FilledButton from "../../../../../Components/FileButton";
import CommonModal from "../../../../../hoc/CommonModal";
import {
  createOfferPackageList,
  getOfferPackagesList,
  updateOfferList,
  updateOfferPackageList,
} from "../../../../../Redux/Bonus/action";
import { jsonToFormData, profileImages } from "utils";
import user from "../../../../../assets/images/avatar.png";
import icon_plus from "../../../../../assets/images/plus.svg";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import PercentageDropdown from "Components/Dropdown/PercentageDropdown";
import CommonDropdown from "Components/Dropdown/CommonDropdown";
import { getAddInAppList } from "Redux/Master/action";
import PackageIdDropdown from "./PackageIdDropdown";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 0,
  borderRadius: "5px",
  padding: "35px",
};

const AddOfferPackagePopUp = ({
  modalValue,
  handleOpenModal,
  redirectApiHandler,
}) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);
  const [modalDetails, setModalDetails] = useState({
    modalValue: "",
    modalName: "",
    modalIsOpen: false,
  });
  let Modal = PopComponent[modalDetails.modalName];
  const [filterData, setFilterData] = useState({
    bonusPercentage: "",
    packageName: "",
    chipsImage: "",
    isImageUpdated: false,
    inAppPackageId: ""
  });
  const [formData, setFormData] = useState([]);
  const handleChange = (e) => {
    const { value, name } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      let payload = {
        ...filterData,
        inAppPackageId: filterData?.inAppPackageId 
      };
      setLoader(true);
      dispatch(createOfferPackageList(jsonToFormData(payload))).then((res) => {
        if (res.data.success) {
          setLoader(false);
          redirectApiHandler();
          handleOpenModal("CommonPop", {
            header: "Success",
            body: res?.data?.message,
          });
        } else {
          setLoader(false);
          handleOpenErrorModal("CommonPop", {
            header: "Error",
            body: res?.data?.message || res?.data?.msg,
          });
        }
      });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate();
    }
  };

  useEffect(() => {
    if (modalValue?.isEdit) {
      const { row } = modalValue;
      setFilterData({
        ...filterData,
        packageName: row?.packageName,
        chipsImage: row?.chipsImage,
        bonusPercentage: row?.bonusPercentage,
        inAppPackageId: row?.inAppPackageId?._id
      });
    }
  }, [modalValue]);

  const handleEditSubmit = (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      let payload = {
        ...filterData,
        offerPackagesId: modalValue?.row?._id,
      };
      setLoader(true);
      dispatch(updateOfferPackageList(jsonToFormData(payload))).then((res) => {
        if (res.data.success) {
          setLoader(false);
          redirectApiHandler();
          handleOpenModal("CommonPop", {
            header: "Success",
            body: res?.data?.message,
          });
        } else {
          setLoader(false);
          handleOpenErrorModal("CommonPop", {
            header: "Error",
            body: res?.data?.message || res?.data?.msg,
          });
        }
      });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate();
    }
  };

  const handleOpenErrorModal = (type, data) => {
    switch (type) {
      case "CommonPop": {
        setModalDetails({
          ...modalDetails,
          modalValue: data,
          modalName: type,
          modalIsOpen: true,
        });
        break;
      }
      default: {
        setModalDetails({ ...modalDetails, modalIsOpen: false });
      }
    }
  };

  useEffect(() => {
    getOfferListDetails();
  }, []);

  const getOfferListDetails = () => {
    let payload = {};
    setLoader(true);
    dispatch(getAddInAppList(payload)).then((res) => {
      if (res.data.success) {
        setLoader(false);
        setFormData({
          ...formData,
          list: res?.data?.data?.docs,
        });
      }
    });
  };
  return (
    <Box sx={style}>
      <div
        className={"modal_main_popup bot_section_details lobby_section_details"}
      >
        <div style={{ marginBottom: "20px" }} className={"modal_popup_title"}>
          <h2>
            {modalValue?.isEdit ? "Update Offer Package" : "Add Offer Package"}
          </h2>
        </div>
        <div className={"add_admin_user_popup_content mt_15"}>
          <form
            onSubmit={
              modalValue?.isEdit
                ? (e) => handleEditSubmit(e)
                : (e) => handleSubmit(e)
            }
          >
            <div className={"level_popup_form_field "}>
              <div className="form_group profile new_game_section profile-image-dropdown">
                <div className="user_profile">
                  <div className="user_profile_pic">
                    {profileImages(filterData?.chipsImage, user)}
                    {!filterData?.chipsImage && (
                      <span className="addnew">
                        <img src={icon_plus} alt="" />
                        <input
                          type="file"
                          name="profileImage"
                          id=""
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (
                              file?.type?.includes("image/") &&
                              file.type !== "image/gif" &&
                              file.type !== "image/svg+xml"
                            ) {
                              if (file.size <= 5 * 1024 * 1024) {
                                // Check if file size is less than or equal to 5 MB
                                setFilterData({
                                  ...filterData,
                                  chipsImage: file,
                                  isImageUpdated: true,
                                });
                              } else {
                                handleOpenErrorModal("CommonPop", {
                                  header: "Error",
                                  body: "Please select an image file smaller than 5 MB.",
                                });
                              }
                            } else {
                              handleOpenErrorModal("CommonPop", {
                                header: "Error",
                                body: "Please add only .jpg, .jpeg, .png files.",
                              });
                            }
                          }}
                        />
                      </span>
                    )}
                  </div>
                  <label htmlFor="" className="profile_label">
                    {" "}
                    Package Image <spa className={"validation-star"}>*</spa>
                  </label>
                </div>
                {simpleValidator.current.message(
                  "Profile Image",
                  filterData?.chipsImage,
                  "required"
                )}
                {filterData?.chipsImage && (
                  <div
                    className={"close-icon"}
                    onClick={() =>
                      setFilterData({ ...filterData, chipsImage: "" })
                    }
                  >
                    <CloseSharpIcon />
                  </div>
                )}
              </div>
              <div className={"level_popup_form_field_left"}>
                <div className={"user_kyc_section"}>
                  <div className={"user_kyc_section_filed"}>
                    <label>
                      Package Name <span className={"validation-star"}>*</span>
                    </label>
                    <div
                      className={
                        "user_kyc_section_input_filed lobby-type-description"
                      }
                    >
                      <input
                        type={"text"}
                        placeholder={"Enter Package Name"}
                        maxLength={20}
                        value={filterData?.packageName}
                        name={"packageName"}
                        onChange={(e) => handleChange(e)}
                      />
                      <span>{filterData?.packageName?.length || 0}/20</span>
                    </div>
                    {simpleValidator.current.message(
                      "packageName",
                      filterData?.packageName,
                      "required"
                    )}
                  </div>
                </div>
                <div className="formData w_100 notification_dropdown">
                  <label>
                    Package Id <span className={"validation-star"}>*</span>
                  </label>
                  <div className="emailWrap mt_margin">
                    <PackageIdDropdown
                      setFormData={setFilterData}
                      formData={filterData}
                      options={formData?.list?.map((item) => ({_id: item._id,gameName: item.packageId,}))} 
                      name={"inAppPackageId"}
                      isAppDownload={""}
                      isLiveGame={true}
                      placeholder={"Select Package Id"}
                    />
                  </div>
                  {simpleValidator.current.message(
                    "inAppPackageId",
                    filterData?.inAppPackageId?.toString(),
                    "required"
                  )}
                </div>
                <div className="formData w_100 notification_dropdown">
                  <label>
                    Bonus Percentage{" "}
                    <span className={"validation-star"}>*</span>
                  </label>
                  <div className="emailWrap mt_margin">
                    <PercentageDropdown
                      name={"bonusPercentage"}
                      formData={filterData}
                      setFormData={setFilterData}
                    />
                  </div>
                  {simpleValidator.current.message(
                    "bonusPercentage",
                    filterData?.bonusPercentage?.toString(),
                    "required"
                  )}
                </div>
              </div>
            </div>
            <div className={"filterData_btn d_flex_end mt_2"}>
              <button
                className={"btn_default"}
                type={"reset"}
                onClick={() => handleOpenModal()}
              >
                Cancel
              </button>
              <FilledButton
                type={"submit"}
                value={modalValue?.isEdit ? "Update" : "Save"}
                className={"btn loader_css"}
                loading={loader}
              />
            </div>
          </form>
        </div>
      </div>
      <CommonModal
        className={"Approved-reject-section"}
        modalIsOpen={modalDetails.modalIsOpen}
        handleOpenModal={handleOpenErrorModal}
      >
        <Modal
          modalValue={modalDetails.modalValue}
          handleOpenModal={handleOpenErrorModal}
          modalIsOpen={modalDetails.modalIsOpen}
        />
      </CommonModal>
    </Box>
  );
};
export default AddOfferPackagePopUp;
