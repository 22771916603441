import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";
import moment from "moment";
import SimpleReactValidator from "simple-react-validator";
import PopComponent from "../../../../../hoc/PopContent";
import FilledButton from "../../../../../Components/FileButton";
import CommonModal from "../../../../../hoc/CommonModal";
import {
  createOfferList,
  getOfferPackagesList,
  updateOfferList,
} from "../../../../../Redux/Bonus/action";
import CheckboxesTags from "./OfferPackageDropDown";
import { jsonToFormData, profileImages } from "utils";
import PackageIdDropdown from "./PackageIdDropdown";
import { getAddInAppList } from "Redux/Master/action";
import icon_plus from "../../../../../assets/images/plus.svg";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import user from "../../../../../assets/images/avatar.png";
import PercentageDropdown from "Components/Dropdown/PercentageDropdown";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 0,
  borderRadius: "5px",
};

const AddOffer = ({ modalValue, handleOpenModal, redirectApiHandler }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);
  const [modalDetails, setModalDetails] = useState({
    modalValue: "",
    modalName: "",
    modalIsOpen: false,
  });
  let Modal = PopComponent[modalDetails.modalName];
  const [filterData, setFilterData] = useState({
    startDate: "",
    endDate: "",
    offerName: "",
    offerDescription: "",
    offerBonus: "",
    offerPackagesIds: "",
    offerImage: "",
    isImageUpdated: false,
    offerDiscount: "",
  });
  const [formData, setFormData] = useState([]);
  const [openCale, setOpenCale] = useState({
    endDate: false,
    startDate: false,
  });
  const [Package, setPackage] = useState([]);
  const handleChange = (e) => {
    const { value, name } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
    });
  };
  // useEffect(() => {
  //   getOfferListDetails();
  // }, []);

  // const getOfferListDetails = () => {
  //   let payload = {};
  //   setLoader(true);
  //   dispatch(getOfferPackagesList(payload)).then((res) => {
  //     if (res.data.success) {
  //       setLoader(false);
  //       setPackage({
  //         ...Package,
  //         list: res?.data?.data?.docs,
  //         totalDocs: res?.data?.data?.totalDocs,
  //       });
  //     }
  //   });
  // };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      let payload = {
        ...filterData,
      };
      setLoader(true);
      dispatch(createOfferList(jsonToFormData(payload))).then((res) => {
        if (res.data.success) {
          setLoader(false);
          redirectApiHandler();
          handleOpenModal("CommonPop", {
            header: "Success",
            body: res?.data?.message,
          });
        } else {
          setLoader(false);
          handleOpenErrorModal("CommonPop", {
            header: "Error",
            body: res?.data?.message || res?.data?.msg,
          });
        }
      });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate();
    }
  };
  useEffect(() => {
    if (modalValue?.isEdit) {
      const { row } = modalValue;
      setFilterData({
        ...filterData,
        offerName: row?.offerName,
        offerBonus: row?.offerBonus,
        offerDescription: row?.offerDescription,
        startDate: row?.startDate,
        endDate: row?.endDate,
        offerPackagesIds: row?.offerPackagesIds?._id,
        offerImage: row?.offerImage,
        offerId: row?._id,
        offerDiscount: row?.offerDiscount
      });
    }
  }, [modalValue]);



  const handleEditSubmit = (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      let payload = {
        ...filterData,
      };
      if (!payload.isImageUpdated) {
        delete payload.offerImage;
      }
      setLoader(true);
      dispatch(updateOfferList(jsonToFormData(payload)))
        .then((res) => {
          if (res.data.success) {
            redirectApiHandler();
            setLoader(false);
            handleOpenModal("CommonPop", {
              header: "Success",
              body: res.data.message,
            });
          } else {
            setLoader(false);
            handleOpenModal("CommonPop", {
              header: "Error",
              body: res.data.message || res?.data?.msg,
            });
          }
        })
        .catch((e) => {
          setLoader(false);
        });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate();
    }
  };

  const handleOpenErrorModal = (type, data) => {
    switch (type) {
      case "CommonPop": {
        setModalDetails({
          ...modalDetails,
          modalValue: data,
          modalName: type,
          modalIsOpen: true,
        });
        break;
      }
      default: {
        setModalDetails({ ...modalDetails, modalIsOpen: false });
      }
    }
  };

  const handleDatePicker = (newValue, type) => {
    if (type === "startDate") {
      setFilterData({ ...filterData, [type]: newValue, endDate: null });
    } else {
      setFilterData({ ...filterData, [type]: newValue });
    }

    setOpenCale({ ...openCale, [type]: false });
  };

  useEffect(() => {
    getOfferListDetailsFun();
  }, []);

  const getOfferListDetailsFun = () => {
    let payload = {};
    setLoader(true);
    dispatch(getAddInAppList(payload)).then((res) => {
      if (res.data.success) {
        setLoader(false);
        setFormData({
          ...formData,
          list: res?.data?.data?.docs,
        });
      }
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (
      file?.type?.includes("image/") &&
      file.type !== "image/gif" &&
      file.type !== "image/svg+xml"
    ) {
      if (file.size <= 5 * 1024 * 1024) {
        // Check if file size is less than or equal to 5 MB
        setFilterData({
          ...filterData,
          offerImage: file,
          isImageUpdated: true,
        });
      } else {
        handleOpenErrorModal("CommonPop", {
          header: "Error",
          body: "Please select an image file smaller than 5 MB.",
        });
      }
    } else {
      handleOpenErrorModal("CommonPop", {
        header: "Error",
        body: "Please add only .jpg, .jpeg, .png files.",
      });
    }
  };
  return (
    <Box sx={style}>
      <div
        className={
          "add_admin_user_popup modal_main_popup offer-details-section"
        }
      >
        <div
          className={
            "add_admin_user_popup_title modal_popup_title offer-details-section-title"
          }
        >
          <h2>{modalValue?.isEdit ? "Update Offer" : "Add Offer"}</h2>
        </div>
        <div className={"coupon_section_form offer-details-section"}>
          <form
            method={"POST"}
            onSubmit={
              modalValue?.isEdit
                ? (e) => handleEditSubmit(e)
                : (e) => handleSubmit(e)
            }
          >
            <div className="formData">
              <label>
                Title <span className={"validation-star"}>*</span>
              </label>
              <div className="emailWrap">
                <input
                  type="text"
                  value={filterData?.offerName}
                  className={"wrap_input_modal"}
                  maxLength={20}
                  name="offerName"
                  placeholder={"Enter Offer Title"}
                  onChange={(e) => handleChange(e)}
                />
                <span>{filterData?.offerName?.length}/20</span>
              </div>
              {simpleValidator.current.message(
                "offerName",
                filterData?.offerName,
                "required"
              )}
            </div>

            <div className="formData">
              <label>Description</label>
              <div className="emailWrap offer-description">
                <textarea
                  maxLength={40}
                  rows={4}
                  name={"offerDescription"}
                  value={filterData?.offerDescription}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
            <div className={"date-picker_coupon"}>
              <div className={"start-date-picker"}>
                <label>
                  Start Date <span className={"validation-star"}>*</span>
                </label>
                <div className={"date_picker_value"}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      name="startDate"
                      value={filterData?.startDate}
                      onChange={(newValue) =>
                        handleDatePicker(newValue, "startDate")
                      }
                      open={openCale.startDate}
                      onClose={() =>
                        setOpenCale({ ...openCale, startDate: false })
                      }
                      minDate={new Date()}
                      renderInput={({ inputProps, ...restParams }) => {
                        return (
                          <TextField
                            {...restParams}
                            onClick={() =>
                              setOpenCale({
                                ...openCale,
                                startDate: !openCale.startDate,
                              })
                            }
                            inputProps={{
                              ...inputProps,
                              placeholder: "Select Start Date",
                            }}
                          />
                        );
                      }}
                      inputProps={{ readOnly: true }}
                      inputFormat="MMM dd, yyyy"
                      className={"datePicker_details w_100"}
                    />
                  </LocalizationProvider>
                </div>
                {simpleValidator.current.message(
                  "startDate",
                  filterData?.startDate,
                  "required"
                )}
              </div>
              <div className={"end-date-picker ml_1"}>
                <label>
                  End Date <span className={"validation-star"}>*</span>
                </label>
                <div className={"date_picker_value"}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      name="endDate"
                      value={filterData?.endDate}
                      onChange={(newValue) =>
                        handleDatePicker(newValue, "endDate")
                      }
                      open={openCale.endDate}
                      onClose={() =>
                        setOpenCale({ ...openCale, endDate: false })
                      }
                      inputFormat="MMM dd, yyyy"
                      minDate={
                        filterData?.startDate
                          ? filterData?.startDate
                          : new Date()
                      }
                      renderInput={({ inputProps, ...restParams }) => (
                        <TextField
                          {...restParams}
                          onClick={() =>
                            setOpenCale({
                              ...openCale,
                              endDate: !openCale.endDate,
                            })
                          }
                          inputProps={{
                            ...inputProps,
                            placeholder: "Select End Date",
                          }}
                        />
                      )}
                      inputProps={{ readOnly: true }}
                      className={"w_100"}
                    />
                  </LocalizationProvider>
                </div>
                {simpleValidator.current.message(
                  "endDate",
                  filterData?.endDate,
                  "required"
                )}
              </div>
            </div>
            <div className="formData w_100 notification_dropdown">
              <label>
                Offer Bonus Percentage{" "}
                <span className={"validation-star"}>*</span>
              </label>
              <div className="emailWrap mt_margin">
                <PercentageDropdown
                  name={"offerDiscount"}
                  formData={filterData}
                  setFormData={setFilterData}
                  
                />
              </div>
              {simpleValidator.current.message(
                "offerDiscount",
                filterData?.offerDiscount?.toString(),
                "required"
              )}
            </div>
            <div className="d_flex">

            <div className="formData w_100 notification_dropdown">
              <label>
                Package Id <span className={"validation-star"}>*</span>
              </label>
              <div className="emailWrap mt_margin">
                <PackageIdDropdown
                  setFilterData={setFilterData}
                  filterData={filterData}
                  options={formData?.list?.map((item) => ({
                    _id: item._id,
                    gameName: item.packageId,
                    offerBonus: item.inAppCoins,
                  }))}
                  discount={filterData?.offerDiscount}
                  name={"offerPackagesIds"}
                  isLiveGame={true}
                  placeholder={"Select Package Id"}
                />
              </div>
              {simpleValidator.current.message(
                "offerPackagesIds",
                filterData?.offerPackagesIds?.toString(),
                "required"
              )}
            </div>
            <div className="formData w_100 pb_2 offer-id">
              <label>
                Offer Bonus <span className={"validation-star"}>*</span>
              </label>
              <div className="emailWrap readOnly_field">
                <input
                  type="text"
                  value={filterData?.offerBonus}
                  name="offerBonus"
                  desabled
                  // onChange={(e) => {
                  //   let numberRegex = /^\d+$/;
                  //   setFilterData({
                  //     ...filterData,
                  //     offerBonus: numberRegex.test(e.target.value)
                  //       ? e.target.value
                  //       : "",
                  //   });
                  // }}
                />
              </div>
              {simpleValidator.current.message(
                "offerBonus",
                filterData?.offerBonus?.toString(),
                "required"
              )}
            </div>
            </div>

            {/* <div
              className="w_100 pt_2 "
              style={{ border: "1px solid transparent" }}
            >
              <label>
                Offer Packages <span className={"validation-star"}>*</span>
              </label>
              <CheckboxesTags
                Package={Package?.list ?? []}
                setPackage={setPackage}
                filterData={filterData}
                setFilterData={setFilterData}
                isEdit={modalValue?.isEdit}
              />
               {simpleValidator.current.message(
                "offerPackagesId",
                filterData?.offerPackagesIds?.toString(),
                "required"
              )}
            </div> */}

            <label htmlFor="" className="profile_label">
              Offer Banner <span className={"validation-star"}>*</span>
            </label>
            <div className="form_group profile new_game_section profile-image-dropdown pt_2">
              <div className="user_profile">
                <div className="user_profile_pic">
                  {profileImages(filterData?.offerImage, user)}
                  {!filterData?.offerImage && (
                    <span className="addnew">
                      <img src={icon_plus} alt="" />
                      <input
                        type="file"
                        name="offerImage"
                        id=""
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (
                            file?.type?.includes("image/") &&
                            file.type !== "image/gif" &&
                            file.type !== "image/svg+xml" &&
                            file.type !== "image/webp"
                          ) {
                            if (file.size <= 5 * 1024 * 1024) {
                              // Check if file size is less than or equal to 5 MB
                              setFilterData({
                                ...filterData,
                                offerImage: file,
                                isImageUpdated: true,
                              });
                            } else {
                              handleOpenErrorModal("CommonPop", {
                                header: "Error",
                                body: "Please select an image file smaller than 5 MB.",
                              });
                            }
                          } else {
                            handleOpenErrorModal("CommonPop", {
                              header: "Error",
                              body: "Please add only .jpg, .jpeg, .png files.",
                            });
                          }
                        }}
                      />
                    </span>
                  )}
                </div>
                {simpleValidator.current.message(
                  "offerImage",
                  filterData?.offerImage,
                  "required"
                )}
              </div>
              {filterData?.offerImage && (
                <div
                  style={{ top: "3px" }}
                  className={"close-icon"}
                  onClick={() =>
                    setFilterData({ ...filterData, offerImage: "" })
                  }
                >
                  <CloseSharpIcon />
                </div>
              )}
            </div>
            <div className={"formData_btn"}>
              <button
                className={"btn_default"}
                type={"reset"}
                onClick={() => handleOpenModal()}
              >
                Cancel
              </button>
              <FilledButton
                type={"submit"}
                value={modalValue?.isEdit ? "Update" : "Save"}
                className={"btn loader_css"}
                loading={loader}
              />
            </div>
          </form>
        </div>
      </div>
      <CommonModal
        className={"Approved-reject-section"}
        modalIsOpen={modalDetails.modalIsOpen}
        handleOpenModal={handleOpenErrorModal}
      >
        <Modal
          modalValue={modalDetails.modalValue}
          handleOpenModal={handleOpenErrorModal}
          modalIsOpen={modalDetails.modalIsOpen}
        />
      </CommonModal>
    </Box>
  );
};
export default AddOffer;
