import React, { useCallback, useRef, useState } from "react";
import { Box, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import FilledButton from "../../../FileButton";
import SimpleReactValidator from "simple-react-validator";
import { updateGameStatus } from "../../../../Redux/games/action";
import { approveRejectUserWithdrawalRequest } from "../../../../Redux/user/action";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
};

const RejectedPopup = ({ modalValue, handleOpenModal, redirectApiHandler }) => {
    const dispatch = useDispatch();
    const simpleValidator = useRef(new SimpleReactValidator());
    const [formData, setFormData] = useState();
    const [, updateState] = useState({});
    const forceUpdate = useCallback(() => updateState({}), []);
    const [loader, setLoader] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (simpleValidator.current.allValid()) {
            if (modalValue?.isUserWithdraw) {
                setLoader(true);
                let payload = {
                    withdrawalId: modalValue?.withdrawalId,
                    // isApprove: modalValue?.isApprove,
                    rejectReason: formData
                }
                if(modalValue?.isRejected){
                    payload ={
                        ...payload,
                        action: 'reject'
                    }
                }else {
                    payload ={
                        ...payload,
                        action: 'refund'
                    }
                }
                dispatch(approveRejectUserWithdrawalRequest(payload)).then(res => {
                    if (res?.data?.success) {
                        redirectApiHandler();
                        setLoader(false);
                        handleOpenModal('CommonPop', { header: "Success", body: res?.data?.message })
                    } else {
                        setLoader(false);
                        handleOpenModal('CommonPop', { header: "Success", body: res?.data?.message || res?.data?.msg })
                    }
                })
            } else if (modalValue?.isPublisherStatus) {

            } else {
                setLoader(true);
                let payload = {
                    ...modalValue,
                    rejectReason: formData
                }
                dispatch(updateGameStatus(payload)).then(res => {
                    if (res?.data?.success) {
                        redirectApiHandler();
                        setLoader(false);
                        handleOpenModal('CommonPop', { header: "Success", body: res?.data?.message })
                    } else {
                        setLoader(false);
                        handleOpenModal('CommonPop', { header: "Success", body: res?.data?.message || res?.data?.msg })
                    }
                })
            }
        } else {
            simpleValidator.current.showMessages();
            forceUpdate();
        }

    }
    return (
        <Box sx={style} className={'user_popup_section'}>
            <div className='d_flex justify_content_center'>
                <Typography id="modal-modal-title" variant="h6" component="h2" className={"block-user-title"}>
                    {
                        `${!modalValue?.isRejected ? 'Do you want to refund this request?' : (modalValue?.isUserWithdraw || modalValue?.isPublisherStatus) ? 'Do you want to reject this request?' : 'Do you want to reject the game?'}`
                    }

                </Typography>
            </div>
            <div className='publisher_popup'>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className={'textarea_form_field'}>
                        <textarea rows={4}
                            placeholder={`${ !modalValue?.isRejected ? 'Reason For Refunding Request' : (modalValue?.isPublisherStatus) ? 'Please Input Transaction ID' : 'Reason For Rejecting Request'}`}
                            onChange={(e) => setFormData(e.target.value)} />
                        {simpleValidator.current.message("Reason", formData, 'required')}
                    </div>
                    <div className='form_main'>
                        <Box mb={3} className={"publisher_popup_box"}>
                            <Button className={'cancel_btn'} variant="outlined" type='reset' onClick={() => handleOpenModal()}> No </Button>
                            <FilledButton type={'submit'} value={'Yes'} className={'submit_btn loader_css'} loading={loader} />
                        </Box>
                    </div>
                </form>
            </div>
        </Box>
    )
}
export default RejectedPopup