import {
    ADD_AVATAR,
    ADD_REASON_REPORT,
    CREATE_OFFER_IMAGE,
    DELETE_AVATAR, DELETE_OFFER_IMAGE_DATA, DELETE_REASON_REPORT,
    GET_ALL_OFFER_IMAGES_DATA,
    GET_AVATAR,
    GET_REASON_REPORT,
    UPDATE_AVATAR,
    UPDATE_OFFER_IMAGE,
    UPDATE_REASON_REPORT
} from "../../route";

export const getAllAvatars = (payload) => async (dispatch, getState, api) => {
  return await api
    .post(GET_AVATAR, payload)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: "GET_ALL_AVATAR",
          payload: res.data.data,
        });
      }
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};
export const createAvatarData =
  (payload) => async (dispatch, getState, api) => {
    return await api
      .post(ADD_AVATAR, payload)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err.response;
      });
  };
export const editAvatarData = (payload) => async (dispatch, getState, api) => {
  return await api
    .put(UPDATE_AVATAR, payload)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: "UPDATE_AVATAR_DATA",
          payload: res.data.data,
        });
      }
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteAvatarData =
  (payload) => async (dispatch, getState, api) => {
    return await api
      .delete(DELETE_AVATAR, { data: payload })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err.response;
      });
  };


export const addReasonReport =
    (payload) => async (dispatch, getState, api) => {
        return await api
            .post(ADD_REASON_REPORT, payload)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };

export const getReasonReport =
    (payload) => async (dispatch, getState, api) => {
        return await api
            .post(GET_REASON_REPORT, payload)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };

export const updateReasonReport =
    (payload) => async (dispatch, getState, api) => {
        return await api
            .put(UPDATE_REASON_REPORT, payload)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };

export const deleteReasonReport =
    (payload) => async (dispatch, getState, api) => {
        return await api
            .post(DELETE_REASON_REPORT, payload)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };

    export const getAllOfferImagesData = (payload) => async (dispatch, getState, api) => {
      return await api
        .post(GET_ALL_OFFER_IMAGES_DATA, payload)
        .then((res) => {
          if (res.status === 200) {
            dispatch({
              type: "GET_ALL_AVATAR",
              payload: res.data.data,
            });
          }
          return res;
        })
        .catch((err) => {
          return err.response;
        });
    };

 export const createOfferImageData =
  (payload) => async (dispatch, getState, api) => {
    return await api
      .post(CREATE_OFFER_IMAGE, payload)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err.response;
      });
  };

  export const editOfferImageData = (payload) => async (dispatch, getState, api) => {
    return await api
      .put(UPDATE_OFFER_IMAGE, payload)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "UPDATE_AVATAR_DATA",
            payload: res.data.data,
          });
        }
        return res;
      })
      .catch((err) => {
        return err.response;
      });
  };

  export const deleteOfferImageData =
  (payload) => async (dispatch, getState, api) => {
    return await api
      .delete(DELETE_OFFER_IMAGE_DATA, { data: payload })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err.response;
      });
  };