import React, {useEffect, useRef, useState} from "react";
import {isValidUrl} from "../../../../../../../utils";
import ReactPlayer from "react-player";
import PopComponent from "hoc/PopContent";
import CommonModal from "hoc/CommonModal";

const VideoTab = ({formData, setFormData, handleOpenModalError, simpleValidator}) => {
    const videoRef = useRef();
    const [modalDetails, setModalDetails] = useState({
        modalValue: "",
        modalName: "",
        modalIsOpen: false,
      });
      let Modal = PopComponent[modalDetails.modalName];
    function youtube_parser(url){
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return (match&&match[7].length === 11)? match[7] : false;
    }

    useEffect(() => {
        if(videoRef?.current){
            videoRef?.current?.load();
            videoRef?.current?.play();
        }
    }, [formData?.howToPlayVideo]);
    const handleOpenErrorModal = (type, data) => {
        switch (type) {
          case "CommonPop": {
            setModalDetails({
              ...modalDetails,
              modalValue: data,
              modalName: type,
              modalIsOpen: true,
            });
            break;
          }
          default: {
            setModalDetails({ ...modalDetails, modalIsOpen: false });
          }
        }
      };
    return(
        <>
            <div className="formData formData_field header_slider_details_Ads">
                <div className='user_profile'>
                    <div className={'header_section_slider'}>
                        <div className='user_profile_pic'>
                            <p>Choose Video</p>
                            <span className="add_new">
                <input
                  type="file"
                  name="playVideo"
                  accept=".mp4"
                  onChange={(e) => {
                    if (e.target.files[0]?.type?.includes("video/")) {
                      const file = e.target.files[0];
                      if (
                        file?.type?.includes("video/") 
                      ) {
                        if (file.size <= 5 * 1024 * 1024) {
                          // Check if file size is less than or equal to 5 MB
                          setFormData({
                            ...formData,
                            howToPlayVideo: file,
                            isVideoUpdate: true,
                          });
                        } else {
                          handleOpenErrorModal("CommonPop", {
                            header: "Error",
                            body: "Please select an image file smaller than 5 MB.",
                          });
                        }
                      }
                    } else {
                      handleOpenModalError("CommonPop", {
                        header: "Error",
                        body: "Support Only Video File",
                      });
                    }
                  }}
                />
              </span>
                        </div>
                    </div>
                    {simpleValidator.current.message("video", formData?.howToPlayVideo, "required")}
                </div>

                {
                    formData?.howToPlayVideo &&
                    <div className={'video-preview mt_2'}>
                        {
                            youtube_parser(typeof formData?.howToPlayVideo === 'string' ?  formData?.howToPlayVideo : URL.createObjectURL(formData?.howToPlayVideo)) ?
                                <ReactPlayer url={typeof formData?.howToPlayVideo === 'string' ?  formData?.howToPlayVideo : URL.createObjectURL(formData?.howToPlayVideo)}  width="100%" height="100%" />
                                :
                                <video ref={videoRef} width="100%" height="240" controls muted>
                                    <source src={typeof formData?.howToPlayVideo === 'string' ?  formData?.howToPlayVideo : URL.createObjectURL(formData?.howToPlayVideo)} />
                                </video>
                        }
                    </div>
                }
            </div>
            <CommonModal
        className={"Approved-reject-section"}
        modalIsOpen={modalDetails.modalIsOpen}
        handleOpenModal={handleOpenErrorModal}
      >
        <Modal
          modalValue={modalDetails.modalValue}
          handleOpenModal={handleOpenErrorModal}
          modalIsOpen={modalDetails.modalIsOpen}
        />
      </CommonModal>
        </>
    )
}
export default VideoTab