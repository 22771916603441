const HelpAndDesk = () => {
    return(
        <div className={'help_desk'}>
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg"
                 className="u_menu-icon-inner" >
                <path className="u_fill"
                      d="M2 9v4.445c2.693 0 3.145 1.99 3.145 4.444 0 2.454-.452 4.444-3.145 4.444v4.445h21.763v-3.232h1.773v3.232H34v-4.445c-2.693 0-3.145-1.99-3.145-4.444 0-2.455.452-4.445 3.145-4.445V9h-8.464v3.232h-1.773V9H2Zm12.454 3.233 1.662 3.762 4.544.252-3.505 2.576 1.136 3.914-3.838-2.172-3.837 2.172 1.136-3.914-3.505-2.576 4.544-.252 1.663-3.762Zm9.309 1.615h1.773v3.233h-1.773v-3.232Zm0 4.849h1.773v3.232h-1.773v-3.232Z"
                      fill="#97a6ba"></path>
            </svg>
            </div>
    )
}
export default HelpAndDesk