import React, {useCallback, useEffect, useRef, useState} from "react";
import Box from "@material-ui/core/Box";
import {jsonToFormData, profileImages} from "../../../../../utils";
import user from "../../../../../assets/images/avatar.png";
import icon_plus from "../../../../../assets/images/plus.svg";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import FilledButton from "../../../../../Components/FileButton";
import CommonModal from "../../../../../hoc/CommonModal";
import {useDispatch} from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import PopComponent from "../../../../../hoc/PopContent";
import {createHomeScreenFooterIcon, updateHomeScreenFooterIcon} from "../../../../../Redux/Design/action";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "5px",
};

const AddHomeScreenFooterIconPopup = ({  modalValue, handleOpenModal, redirectApiHandler, }) =>{
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);
  const [modalDetails, setModalDetails] = useState({ modalValue: '', modalName: '', modalIsOpen: false });
  let Modal = PopComponent[modalDetails.modalName];
  const [formData, setFormData] = useState({
    iconName: "",
    selectedIcon: "",
    nonSelectedIcon: "",
    isSelectedIconImageUpdated: false,
    isNonSelectedIconImageUpdated: false,
  });

  const changeHandler = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: value
    })
  };

  const handleOpenErrorModal = (type, data) => {
    switch (type) {
      case 'CommonPop': {
        setModalDetails({ ...modalDetails, modalValue: data, modalName: type, modalIsOpen: true });
        break;
      }
      default: {
        setModalDetails({ ...modalDetails, modalIsOpen: false })
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      let payload = {
        ...formData
      }
      setLoader(true)
      dispatch(createHomeScreenFooterIcon(jsonToFormData(payload))).then((res) => {
        if (res.data.statusCode === 200 && res.data.success) {
          setLoader(false);
          redirectApiHandler()
          handleOpenModal('CommonPop', { header: "Success", body: res.data.message })
        } else {
          setLoader(false)
          handleOpenModal('CommonPop', { header: "Error", body: res.data.message || res?.data?.msg })
        }
      });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate();
    }
  };

  const handleIconChange = (e) => {
    if(e.target.files[0]?.type?.includes('image/') &&  e.target.files[0].type !== 'image/gif' && e.target.files[0].type !== "image/svg+xml"){
      if (e.target.name === "selectedIcon") {
        setFormData({...formData, selectedIcon: e.target.files[0], isSelectedIconImageUpdated: true,});
      } else {
        setFormData({...formData, nonSelectedIcon: e.target.files[0], isNonSelectedIconImageUpdated: true,});
      }
    }else {
      handleOpenErrorModal('CommonPop', { header: "Error", body: 'Please Enter only .jpg, .png, and .jpeg file' });
    }

  }

  const handleEditSubmit = (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      let payload = {
        ...formData,
        homeScreenIconId: modalValue?.item?._id,
      }
      if (!payload.isSelectedIconImageUpdated) {
        delete payload.selectedIcon;
      }
      if (!payload.isNonSelectedIconImageUpdated) {
        delete payload.nonSelectedIcon;
      }
      setLoader(true)
      dispatch(updateHomeScreenFooterIcon(jsonToFormData(payload))).then((res) => {
        if (res.data.success) {
          redirectApiHandler();
          setLoader(false)
          handleOpenModal('CommonPop', { header: "Success", body: res.data.message });
        } else {
          setLoader(false)
          handleOpenModal('CommonPop', { header: "Error", body: res.data.message || res?.data?.msg });
        }
      }).catch(e => {
        setLoader(false)
      })
    } else {
      simpleValidator.current.showMessages();
      forceUpdate();

    }
  }

  useEffect(()=>{
    if(modalValue?.isEdit){
      setFormData({
        ...formData,
        iconName: modalValue?.item?.iconName,
        selectedIcon: modalValue?.item?.selectedIconImage,
        nonSelectedIcon: modalValue?.item?.nonSelectedIconImage,
      })
    }
  },[modalValue?.isEdit])

  return(
      <Box sx={style}>
        <div className={'add_admin_user_popup modal_main_popup add_avatar_section lobby_section_details'}>
          <div className={'modal_popup_title'}>
            <h2>{modalValue?.isEdit ? 'Update Home Screen Footer Icons' : 'Add Home Screen Footer Icons'}</h2>
          </div>
          <div className={'add_admin_user_popup_content mt_15'}>
            <form onSubmit={modalValue?.isEdit ? (e) => handleEditSubmit(e) : (e) => handleSubmit(e)}>
              <div className={'level_popup_form_field '}>
                <div className={'d_flex'}>
                  <div className='form_group profile new_game_section profile-image-dropdown'>
                    <div className='user_profile'>
                      <div className='user_profile_pic'>
                        {profileImages(formData?.selectedIcon, user)}
                        {
                          !formData?.selectedIcon &&
                          <span className='addnew'>
                            <img src={icon_plus} alt='' />
                            <input type='file' name='selectedIcon' id='' onChange={(e) => handleIconChange(e)} />
                        </span>
                        }
                      </div>
                      <label htmlFor='' className='profile_label'>Selected Footer Icon <span className={'validation-star'}>*</span></label>
                    </div>
                    {simpleValidator.current.message("selectedIcon", formData?.selectedIcon, 'required')}
                    {
                      formData?.selectedIcon &&
                      <div className={'close-icon'} onClick={()=> setFormData({...formData,selectedIcon:'',isSelectedIconImageUpdated:false})}>
                        <CloseSharpIcon/>
                      </div>
                    }
                  </div>
                  <div className='form_group profile new_game_section profile-image-dropdown ml_1'>
                    <div className='user_profile'>
                      <div className='user_profile_pic'>
                        {profileImages(formData?.nonSelectedIcon, user)}
                        {
                          !formData?.nonSelectedIcon &&
                          <span className='addnew'>
                            <img src={icon_plus} alt='' />
                            <input type='file' name='labelIcon' id='' onChange={(e) => handleIconChange(e)} />
                        </span>
                        }
                      </div>
                      <label htmlFor='' className='profile_label'>Non-Selected Footer Icon<span className={'validation-star'}>*</span></label>
                    </div>
                    {simpleValidator.current.message("nonSelectedIcon", formData?.nonSelectedIcon, 'required')}
                    {
                      formData?.nonSelectedIcon &&
                      <div className={'close-icon'} onClick={()=> setFormData({...formData,nonSelectedIcon:'',isNonSelectedIconImageUpdated:false})}>
                        <CloseSharpIcon/>
                      </div>
                    }
                  </div>
                </div>
                <div className={'level_popup_form_field_left'}>
                  <div className={'user_kyc_section'}>
                    <div className={'user_kyc_section_filed'}>
                      <label>Title <span className={'validation-star'}>*</span></label>
                      <div className={'user_kyc_section_input_filed lobby-type-description'}>
                        <input type={'text'} placeholder={'Enter Title'} value={formData?.iconName} name={'iconName'} onChange={(e) => changeHandler(e)} />
                        {/*<span>{formData?.avatarName?.length}/20</span>*/}
                      </div>
                      {simpleValidator.current.message("title", formData?.iconName, 'required')}
                    </div>
                  </div>
                </div>
              </div>
              <div className={'formData_btn'}>
                <button className={'btn_default'} type={'reset'} onClick={() => handleOpenModal()}>Cancel</button>
                <FilledButton type={'submit'} value={modalValue?.isEdit ? 'Update' : 'Save'} className={'btn loader_css'} loading={loader} />
              </div>
            </form>
          </div>
        </div>
        <CommonModal className={'Approved-reject-section'} modalIsOpen={modalDetails.modalIsOpen} handleOpenModal={handleOpenErrorModal}>
          <Modal modalValue={modalDetails.modalValue} handleOpenModal={handleOpenErrorModal} modalIsOpen={modalDetails.modalIsOpen} />
        </CommonModal>
      </Box>
  )
}

export default AddHomeScreenFooterIconPopup