import { Box } from "@mui/material";
import FilledButton from "../../../FileButton";
import React, { useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import {getUserProfile, updateCoins} from "../../../../Redux/user/action";
import {useParams} from "react-router-dom";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
};
const AddCoinPopup = ({ modalValue, handleOpenModal }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [loader] = useState(false);
    const simpleValidator = useRef(new SimpleReactValidator());
    const [, updateState] = useState({});
    const forceUpdate = useCallback(() => updateState({}), []);
    const [formData, setFormData] = useState({
        userId: modalValue.id,
        bonus: ''
    })

    const handleSubmit = (e) => {
        e.preventDefault();
        if (simpleValidator.current.allValid()) {
            let payload = {
                ...formData,
            }
            dispatch(updateCoins(payload)).then(res => {
                if (res.data.success) {
                    dispatch(getUserProfile({ userId: id })); // Dispatches an action to fetch user profile details using the 'id'
                    handleOpenModal('CommonPop', { header: "Success", body: res.data.message })
                } else {
                    handleOpenModal('CommonPop', { header: "Error", body: res.data.message || res?.data?.msg })
                }
            })
        } else {
            simpleValidator.current.showMessages();
            forceUpdate();
        }
    }
    const handleChange = (e) => {
        let numberRegex = /^\d+$/;
        const { value, name } = e.target
        setFormData({
            ...formData,
            [name]: numberRegex.test(value) ? /^0/.test(value) ? value.replace(/^0/, "") : value: '',
            type: name
        })
    };
    return (
        <Box sx={style}>
            <div className={'modal_main_popup add_admin_user_popup'}>
                <div className={'modal_popup_title'}>
                    <h2>Add Coins</h2>
                </div>
                <div className={'add_admin_user_popup_content_pop'}>
                    <form method={'POST'} onSubmit={(e) => handleSubmit(e)}>
                        <div className="formData">
                            <label>Coins<span className={'validation-star'}>*</span> </label>
                            <div className="emailWrap">
                                <input type="text" maxLength={5} name='bonus' value={formData?.bonus} onChange={(e) => handleChange(e)} />
                                <span>{formData?.bonus?.length}/5</span>
                            </div>
                            {simpleValidator.current.message("coin", formData?.bonus, "required")}
                        </div>
                        <div className={'formData_btn'}>
                            <button className={'btn_default mr_2'} onClick={() => handleOpenModal()}>Cancel</button>
                            <FilledButton type={'submit'} value={'Submit'} className={'btn loader_css'} loading={loader} />
                        </div>
                    </form>
                </div>
            </div>
        </Box>
    )
}
export default AddCoinPopup